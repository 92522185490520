<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 500],
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Programmes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog :retain-focus="false" v-model="dialog" max-width="1000px">
              <template v-slot:activator="{}">
                <v-btn color="primary" dark class="mb-2" v-on:click="addItem">New Item</v-btn>
                <!-- <v-select
                  :items="websites"
                  label="Connects"
                  v-model="selectedConnectIdForFilter"
                  item-text="domain_data.title"
                  item-value="id"
                  class="mr-4 mt-8"
                  style="max-width:300px"
                  :menu-props="{ closeOnContentClick: true }"
                  @change="websiteFilterActivated($event)"
                >
                  <template v-slot:prepend-item>
                    <v-list-item @click="websiteFilterActivated(selectedConnectIdForFilter = '')">
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select> -->
              </template>
              <v-card >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form
                  ref="form"
                  @submit.prevent="save()"
                  v-model="valid"
                  action="/"
                  v-if="!spinnerActive"
                >
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-file-input
                            accept="image/*"
                            :rules="[!!dataImage || 'Required.', rules.size]"
                            placeholder="Pick an image"
                            v-model="editedItem.image"
                            :input="loadImage()"
                            label="Card image"
                            prepend-icon="mdi-paperclip"
                            outlined
                            :show-size="1000"
                          >
                          </v-file-input>
                          <img
                            v-if="dataImage"
                            :src="getImage(dataImage)"
                            height="100"
                            class="mb-4"
                          />
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :rules="[rules.required]"
                                v-bind="attrs"
                                v-on="on"
                                v-model="editedItem.alt_image"
                                label="SEO - Image alt (text that describes image)"
                              ></v-text-field>
                            </template>
                            <span>The Alt tags are used to describe the image to
                              <br>search engines (cannot be seen by the user).
                              <br>Try to create a simple description of the image
                              <br>as if you were describing it to someone with their eyes closed.
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.title"
                            label="Program title"
                          ></v-text-field>
                          <v-textarea
                            v-model="editedItem.short_description"
                            label="Short description"
                          ></v-textarea>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.tab_title"
                                label="SEO - Page title (shown in browser tab)"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <span>SEO Page title - This is the title of the tab of the page and also shows up
                              <br>in search engines as the main title for the page.
                              <br>Keep the title short (max. 60 characters) and
                              <br>utilise keywords from the page.
                            </span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :rules="[rules.required, rules.slug]"
                                v-model="editedItem.slug"
                                v-bind="attrs"
                                v-on="on"
                                label="SEO - Slug (hyphenated text used for creating url) (example: asco-2021-conference-clinical-trial-data)"
                            ></v-text-field>
                            </template>
                            <span>The slug is the part of the URL after the base:
                              <br>www.xyz.com/(slug here). Utilise 3-5 keywords from the page.
                            </span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-textarea
                              v-model="editedItem.og_meta_desc"
                              v-bind="attrs"
                              v-on="on"
                              label="SEO - Meta description (this description will appear on google search results)"
                            ></v-textarea>
                            </template>
                            <span>The meta description is shown in search engines and
                              <br>is used to describe the contents of the page.
                              <br>Keep it between 50-160 characters and try to
                              <br>imagine what a user would search for in order
                              <br>to find the contents of the page.
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-select
                          v-model="editedItem.program_type"
                          :items="[
                            {text: '(empty / no choice)', value: '.'},
                            {text: 'BluePrint Resource', value: 'BluePrint Resource'},
                            {text: 'Conference Update', value: 'Conference Update'},
                            {text: 'E-learning', value: 'E-learning'},
                            {text: 'Expert View', value: 'Expert View'},
                            {text: 'Event: Experts Knowledge Share', value: 'Event: Experts Knowledge Share'},
                            {text: 'Key Publication Snapshot', value: 'Key Publication Snapshot'},
                            {text: 'Micro E-learning', value: 'Micro E-learning'},
                            {text: 'Now Published', value: 'Now Published'},
                            {text: 'OncologyPRO', value: 'OncologyPRO'},
                            {text: 'Podcast', value: 'Podcast'},
                            {text: 'Survey', value: 'Survey'},
                            {text: 'Video', value: 'Video'},
                            {text: 'Webinar', value: 'Webinar'},
                          ]"
                          label="Program type"
                          item-text="text"
                          item-value="value"
                          @change="programmeTypeUpdated()"
                        ></v-select>
                      </v-row>
                      <v-row>
                        <v-select
                          v-model="editedItem.media_type"
                          :items="['vimeo','audio','photo']"
                          label="Media type"
                          @change="mediaTypeUpdated()"
                        ></v-select>
                      </v-row>
                      <v-row v-if="editedItem.media_type == 'vimeo'">
                        <v-text-field
                          v-model="editedItem.media_url"
                          label="Vimeo link (video ID only - https://vimeo.com/518793614 -> 518793614)"
                        ></v-text-field>
                      </v-row>
                      <v-row v-if="editedItem.media_type == 'audio'">
                        <v-text-field
                          v-model="editedItem.media_url"
                          label="Audio link"
                        ></v-text-field>
                      </v-row>
                      <v-row v-if="editedItem.media_type == 'photo'">
                        <!-- :rules="[!!dataImage || 'Required.', rules.size]" -->
                        <v-file-input
                          accept="image/*"
                          placeholder="Pick a photo"
                          v-model="editedItem.media_url"
                          :input="loadImage2()"
                          label="Card image"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        >
                        </v-file-input>
                        <div style="width: 100%">
                          <img
                            v-if="dataImage2"
                            :src="getImage(dataImage2)"
                            height="100"
                            class="mb-4"
                          />
                        </div>
                      </v-row>
                      <v-row v-if="editedItem.media_type == 'photo'">
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="editedItem.title"
                          label="Title"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.duration"
                          label="Approximate program read duration (example - 2 min)"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-file-input
                          multiple
                          accept="application/*"
                          placeholder="Pick an document"
                          v-model="editedItem.documents"
                          :input="loadDocument()"
                          label="Downloadable documents (PDF, DOCX, PPTX, XLSX)"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        >
                        </v-file-input>
                        <div style="width: 100%; display: flex; flex-direction: column">
                          <div class="mb-8 ml-4 mr-4" style="width: 100%; display: flex; flex-direction: row" v-for="(document, i) in loadedDocuments" :key="i">
                            <p>{{document.document_name}} <v-icon v-on:click="removeDocument(i)">mdi-close-box</v-icon></p>
                            <v-text-field class="pt-0 mt-0 ml-8 mr-8"
                              v-model="document.btn_name"
                              label="Button text"
                            ></v-text-field>
                          </div>
                        </div>
                      </v-row>
                      <!-- EDITOR AUDIO TRANSCRIPT -->
                      <v-row>
                        <span style="width: 100%" class="mt-8">AUDIO TRANSCRIPT</span>
                        <editor
                          style="width: 100%"
                          v-if="editorReady"
                          v-model="editedItem.description"
                          api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                          :disabled="false"
                          initial-value=""
                          :init="{
                            images_upload_url: `${baseURL}/api/image-upload`,
                            height: 500,
                            menubar: false,
                            document_base_url: `${baseURL}/`,
                            relative_urls: false,
                            remove_script_host: true,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help \
                              link \
                              media image',
                          }"
                        />
                      </v-row>
                      <!-- EDITOR Bottom description -->
                      <v-row>
                        <span style="width: 100%" class="mt-8">Bottom description</span>
                        <editor
                          style="width: 100%"
                          v-if="editorReady"
                          v-model="editedItem.bottom_description"
                          api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                          :disabled="false"
                          initial-value=""
                          :init="{
                            images_upload_url: `${baseURL}/api/image-upload`,
                            height: 500,
                            menubar: false,
                            document_base_url: `${baseURL}/`,
                            relative_urls: false,
                            remove_script_host: true,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help \
                              media image',
                          }"
                        />
                      </v-row>
                      <v-row style="padding: 14px"></v-row>
                      <!-- LABELS START -->
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <h2>LABELS</h2>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <span style="width: 100%" class="mt-8">Selected Labels</span>
                        <template v-if="selectedLabels && selectedLabels.length > 0">
                          <v-chip
                            class="ma-2"
                            close
                            color="green"
                            outlined
                            v-for="selectedProgramme in selectedLabels"
                            :key="selectedProgramme.id"
                            @click:close="removeLabelFromSelected(selectedProgramme)"
                          >
                            {{selectedProgramme.title}}
                          </v-chip>
                        </template>
                        <template v-else>
                          <v-icon>mdi-close-box</v-icon><span class="mt-4 mb-4">No labels have been selected</span>
                        </template>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <span style="width: 100%">Unselected Labels</span>
                        <v-text-field
                          ref="searchLabel"
                          v-model="searchLabel"
                          full-width
                          hide-details
                          label="Search"
                          single-line
                        ></v-text-field>
                      </v-row>
                      <v-row class="mb-4" v-if="selectedLabels" style="background-color: #dcdcdc; padding: 14px; max-height: 216px; overflow-y: auto;">
                        <v-list style="background-color: #dcdcdc; padding: 14px; display: flex; flex-wrap: wrap" v-if="searchLabel !== ''">
                          <template v-for="item in filteredLabels">
                            <v-list-item
                              v-if="!selectedLabels.includes(item)"
                              :key="item.title"
                              @click="selectedLabels.push(item)"
                              style="flex: unset"
                            >
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-list v-else style="background-color: #dcdcdc; padding: 14px; display: flex; flex-wrap: wrap">
                          <template v-for="item in labels">
                            <v-list-item
                              v-if="!selectedLabels.includes(item)"
                              :key="item.id"
                              @click="selectedLabels.push(item)"
                              style="flex: unset"
                            >
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-row>
                      <!-- LABELS END -->
                      <v-row style="padding: 14px"></v-row>
                      <!-- TA START -->
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <h2>THERAPEUTIC AREAS</h2>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <span style="width: 100%">Selected TherapeuticAreas</span>
                        <template v-if="selectedTherapeuticAreas.length > 0">
                          <v-chip
                            class="ma-2"
                            close
                            color="green"
                            outlined
                            v-for="selectedTherapeuticArea in selectedTherapeuticAreas"
                            :key="selectedTherapeuticArea.id"
                            @click:close="removeTherapeuticAreaFromSelected(selectedTherapeuticArea)"
                          >
                            {{selectedTherapeuticArea.thumb_name}}
                          </v-chip>
                        </template>
                        <template v-else>
                          <v-icon>mdi-close-box</v-icon><span class="mt-4 mb-4">No labels have been selected</span>
                        </template>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <span style="width: 100%">Unselected TherapeuticAreas</span>
                        <v-text-field
                          ref="searchTherapeuticArea"
                          v-model="searchTherapeuticArea"
                          full-width
                          hide-details
                          label="Search"
                          single-line
                        ></v-text-field>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px; max-height: 216px; overflow-y: auto;">
                        {{searchTherapeuticArea}}
                        <v-list
                          v-if="searchTherapeuticArea !== ''"
                          style="background-color: #dcdcdc; max-height: 206px; overflow-y: scroll; width: 100%; display: flex; flex-wrap: wrap;"
                        >
                          <template v-for="item in filteredTherapeuticAreas">
                            <v-list-item
                              v-if="!selectedTherapeuticAreas.includes(item)"
                              :key="item.id"
                              @click="selectedTherapeuticAreas.push(item)"
                              style="flex: unset"
                            >
                              <v-list-item-title v-text="item.thumb_name"></v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-list v-else style="background-color: #dcdcdc; max-height: 206px; overflow-y: scroll; width: 100%; display: flex; flex-wrap: wrap;">
                            <template v-for="item in therapeuticAreas">
                              <v-list-item
                                v-if="!selectedTherapeuticAreas.includes(item)"
                                :key="item.id"
                                @click="selectedTherapeuticAreas.push(item)"
                                style="flex: unset"
                              >
                                <v-list-item-title v-text="item.thumb_name"></v-list-item-title>
                              </v-list-item>
                            </template>
                        </v-list>
                      </v-row>
                      <!-- TA END -->
                      <v-row style="padding: 14px"></v-row>
                      <!-- EXPERTS START -->
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <h2>EXPERTS</h2>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <span style="width: 100%">Selected Experts</span>
                        <template v-if="selectedExperts.length > 0">
                          <v-chip
                            class="ma-2"
                            close
                            color="green"
                            outlined
                            v-for="selectedExpert in selectedExperts"
                            :key="selectedExpert.id"
                            @click:close="removeExpertFromSelected(selectedExpert)"
                          >
                            {{selectedExpert.first_name}} {{selectedExpert.last_name}}
                          </v-chip>
                        </template>
                        <template v-else>
                          <v-icon>mdi-close-box</v-icon><span class="mt-4 mb-4">No labels have been selected</span>
                        </template>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px">
                        <span style="width: 100%">Unselected Experts</span>
                        <v-text-field
                          ref="searchExpert"
                          v-model="searchExpert"
                          full-width
                          hide-details
                          label="Search"
                          single-line
                        ></v-text-field>
                      </v-row>
                      <v-row style="background-color: #dcdcdc; padding: 14px; max-height: 216px; overflow-y: auto;">
                        <v-list style="background-color: #dcdcdc; max-height: 206px; overflow-y: scroll; width: 100%; display: flex; flex-wrap: wrap;" v-if="searchExpert !== ''">
                          <template v-for="item in filteredExperts">
                            <v-list-item
                              v-if="!selectedExperts.includes(item)"
                              :key="item.email"
                              @click="selectedExperts.push(item)"
                              style="flex: unset"
                            >
                              <!-- <v-list-item-avatar>
                                <v-icon
                                  v-text="'mdi-alpha-l-circle'"
                                ></v-icon>
                              </v-list-item-avatar> -->
                              <v-list-item-title v-text="item.first_name + ' ' + item.last_name"></v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-list v-else style="display: flex; flex-wrap: wrap; background-color: #dcdcdc; padding: 14px">
                            <template v-for="item in experts">
                              <v-list-item
                                v-if="!selectedExperts.includes(item)"
                                :key="item.email"
                                @click="selectedExperts.push(item)"
                                style="flex: unset"
                              >
                                <!-- <v-list-item-avatar>
                                  <v-icon
                                    v-text="'mdi-alpha-l-circle'"
                                  ></v-icon>
                                </v-list-item-avatar> -->
                                <v-list-item-title v-text="item.first_name + ' ' + item.last_name"></v-list-item-title>
                              </v-list-item>
                            </template>
                        </v-list>
                      </v-row>
                      <!-- EXPERTS END -->
                      <v-row style="padding: 14px"></v-row>
                      <v-row>
                        <v-col cols="12" sm="10">
                          <v-select
                            :items="accreditations"
                            v-model="editedItem.accreditation_id"
                            item-text="title"
                            item-value="id"
                            label="Accreditation"
                            persistent-hint
                            class="mt-8 mb-8"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2" style="display: flex;">
                          <v-icon v-on:click="editedItem.accreditation_id = null">mdi-close-box</v-icon>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.published_at"
                          label="Publish date (this date will appear on program card)"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.first_btn"
                          label="First button"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.first_url"
                          label="First URL"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.second_btn"
                          label="Second button"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.second_url"
                          label="Second URL"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.cta_btn"
                          label="Call-to-action button"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.cta_url"
                          label="Call-to-action URL"
                        ></v-text-field>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text type="submit" :disabled="spinnerActive">Save</v-btn>
                  </v-card-actions>
                </v-form>
                <div v-else class=circular-wrapper>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    :size="120"
                  ></v-progress-circular>
                </div>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions2`]="{ item }">
          <v-switch v-model="item.status" v-on:click="publishUnpublishItem(item)"/>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <a
            style="text-decoration: none"
            target="_blank"
            :href="item.websites && item.websites.length > 0
                   ? 'http://' + item.websites[0].domain + `/program/${item.slug}`
                   : `/program/${item.slug}`"
          >
            <v-icon small class="mr-2">mdi-eye</v-icon>
          </a>
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
        <template v-slot:[`item.labels`]="{ item }">
          <div v-for="label in item.labels" :key="label.id">{{label.title}}</div>
        </template>
        <template v-slot:[`item.therapeutic_area`]="{ item }">
          <div v-for="therapeutic_area in item.therapeutic_area" :key="therapeutic_area.id">{{therapeutic_area.thumb_name}}</div>
          <!-- <div>{{item.therapeutic_area ? item.therapeutic_area.thumb_name : ""}}</div> -->
        </template>
        <template v-slot:[`item.experts`]="{ item }">
          <div v-for="expert in item.experts" :key="expert.id">{{expert.first_name}} {{expert.last_name}}</div>
        </template>
        <template v-slot:[`item.websites`]="{ item }">
          <div v-for="website in item.websites" :key="website.id">
            {{
              website.website_config[website.website_config.findIndex((element, index) => element.key === 'title')].value
              ? website.website_config[website.website_config.findIndex((element, index) => element.key === 'title')].value
              : "n/a"
            }}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  fetchWebsites,
  fetchProgrammes,
  showProgramme,
  addProgramme,
  editProgramme,
  deleteProgramme,
  fetchAllLables,
  fetchAllExperts,
  fetchAllDocs,
  publishUnpublishProgram,
  fetchAllAccreditations,
  fetchAllTherapeuticAreas
} from "@/services";
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "Programmes",
  components: {
    TheSidebar,
  },
  data() {
    return {
      baseUrl: 'https://cor2ed.com',
      modal: false,
      date: new Date().toISOString().substr(0, 10),
      accreditations: [],
      editorReady: false,
      searchLabel: '',
      searchExpert: '',
      searchDoc: '',
      searchWebsite: '',
      searchTherapeuticArea: '',
      valid: false,
      perPage: 10,
      sortDirection: true,
      page: 1,
      total: 0,
      sortBy: "title",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      dataImage: false,
      headers: [
        { text: "Name", align: "start", value: "title" },
        { text: "Connects", value: "websites", sortable: false },
        { text: "Therapeutic area", value: "therapeutic_area", sortable: false },
        { text: "Labels", value: "labels", sortable: false },
        { text: "Experts", value: "experts", sortable: false },
        { text: "Published at", value: "published_at" },
        { text: "Published", value: "actions2", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dataImage2: false,
      selectedConnectIdForFilter: '',
      baseURL: '',
      items: [],
      labels: [],
      experts: [],
      documents: [],
      websites: [],
      selectedLabels: [],
      selectedExperts: [],
      selectedDocs: [],
      therapeuticAreas: [],
      selectedTherapeuticAreas: [],
      fileName: false,
      fileName2: false,
      imageUpdated: false,
      selectedWebsites: [],
      loadedDocuments: [],
      editedIndex: -1,
      editedItem: {
        description: "",
        og_meta_desc: "",
        title: "",
        slug: "",
        media_type: "",
        media_url: "",
      },
      defaultItem: {
        description: "",
        og_meta_desc: "",
        program_type: ".",
        title: "",
        relation_type: "Labels",
        slug: "",
        media_type: "vimeo",
        media_url: "",
        cta_btn: "Go to next section"
      },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item"
    },
    filteredDocs () {
      const searchDoc = this.searchDoc.toLowerCase()
      if (!searchDoc) return this.documents
      return this.documents.filter(item => {
        const text = item.file_name.toLowerCase()
        return text.indexOf(searchDoc) > -1
      })
    },
    filteredWebsites () {
      const searchWebsite = this.searchWebsite.toLowerCase()
      if (!searchWebsite) return this.website
      return this.websites.filter(item => {
        const text = item.domain_data.title.toLowerCase()
        return text.indexOf(searchWebsite) > -1
      })
    },
    filteredLabels () {
      const searchLabel = this.searchLabel.toLowerCase()
      if (!searchLabel) return this.labels
      return this.labels.filter(item => {
        const text = item.title.toLowerCase()
        return text.indexOf(searchLabel) > -1
      })
    },
    filteredTherapeuticAreas () {
      const searchTherapeuticArea = this.searchTherapeuticArea.toLowerCase()
      if (!searchTherapeuticArea) return this.therapeuticAreas
      return this.therapeuticAreas.filter(item => {
        const text = item.thumb_name.toLowerCase()
        return text.indexOf(searchTherapeuticArea) > -1
      })
    },
    filteredExperts () {
      const searchExpert = this.searchExpert.toLowerCase()
      if (!searchExpert) return this.experts
      return this.experts.filter(item => {
        const firstName = item.first_name.toLowerCase()
        if (firstName.indexOf(searchExpert) > -1) {
          return firstName.indexOf(searchExpert) > -1
        } else {
          const lastName = item.last_name.toLowerCase()
          return lastName.indexOf(searchExpert) > -1
        }
      })
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    selectedLabels () {
      this.searchLabel = ''
    },
    selectedExperts () {
      this.searchExpert = ''
    },
    selectedDocs () {
      this.searchDoc = ''
    },
    selectedWebsites () {
      this.searchWebsite = ''
    },
    selectedTherapeuticAreas () {
      this.searchTherapeuticArea = ''
    },
  },
  mounted() {
    this.baseURL = window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz' : window.location.origin
    this.websiteId = { id: this.$route.params.websiteId }
    this.selectedWebsites.push(this.websiteId)
    this.getItems()
    this.getLabels()
    this.getExperts()
    this.getAccreditations()
    this.getWebsites()
    this.getAllTherapeuticAreas()
  },
  methods: {
    websiteFilterActivated(connectId) {
      console.log('connectId', connectId)
      this.getItems()
    },
    programmeTypeUpdated() {
      // this.editedItem.program_type = null
      console.log('programmeTypeUpdated')
    },
    mediaTypeUpdated() {
      this.editedItem.media_url = null
    },
    savePublishedDate() {
      this.$refs.dialog.save(this.editedItem.start_date)
    },
    async getWebsites() {
      await fetchWebsites().then(response => {
        this.websites = response.data
      })
    },
    async getAccreditations() {
      await fetchAllAccreditations().then(response => {
        this.accreditations = response.data.data
      })
    },
    async getAllTherapeuticAreas() {
      await fetchAllTherapeuticAreas().then(response => {
        this.therapeuticAreas = response.data.data
      })
    },
    async getAllDocs() {
      await fetchAllDocs().then(response => {
        this.documents = response.data.data
      })
    },
    async publishUnpublishItem(item) {
      await publishUnpublishProgram(item).then(() => {
        this.getItems()
      })
    },
    async getItems() {
      this.items = []
      setTimeout(async () => {
        if (this.spinnerActive) return
        let tableData = {}
        tableData["perPage"] = this.perPage
        tableData["sortBy"] = this.sortBy
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC"
        tableData["page"] = this.page
        tableData["website_id"] = this.websiteId
        this.spinnerActive = true
        await fetchProgrammes(tableData)
          .then((res) => {
            console.log('fetchProgrammes res -> ', res)
            if (res.data) {
              this.items = res.data.data.data
              this.spinnerActive = false
              this.total = res.data.data.total
            }
          })
          .catch((error) => {
            console.log(error)
            this.spinnerActive = false
          })
      }, 0)
    },
    async getLabels() {
      await fetchAllLables().then((res) => {
        if (res.data) {
          this.labels = res.data.data
        }
      })
    },
    async getExperts() {
      await fetchAllExperts().then((res) => {
        if (res.data) {
          this.experts = res.data.data
        }
      })
    },
    loadImage() {
      if (this.editedItem.image && this.editedItem.image.size) {
        var file = this.editedItem.image
        this.fileName = file.name
        var reader = new FileReader()
        reader.onloadend = async () => {
          this.dataImage = reader.result
          this.editedItem.image = null
          this.imageUpdated = true
        }
        reader.readAsDataURL(file)
      }
    },
    loadImage2() {
      if (this.editedItem.media_url && this.editedItem.media_url.size) {
        console.log('this.editedItem.media_url', this.editedItem.media_url)
        var file = this.editedItem.media_url
        this.fileName2 = file.name
        var reader = new FileReader()
        reader.onloadend = async () => {
          this.dataImage2 = reader.result
          this.editedItem.media_url = null
          this.imageUpdated = true
        }
        reader.readAsDataURL(file)
      }
    },
    loadDocument() {
      if (this.editedItem.documents && this.editedItem.documents.length > 0 && this.editedItem.documents[0].size) {
        var file = this.editedItem.documents[0]
        var reader = new FileReader()
        reader.onloadend = async () => {
          if (this.editedItem.documents.length > 0) {
            // this.loadedDocumentNames.push({document_name: file.name, btn_name: ""})
            this.loadedDocuments.push({base64: reader.result, document_name: file.name, btn_name: ""})
          }
          this.imageUpdated = true
          this.editedItem.documents.shift()
          this.loadDocument()
        }
        reader.readAsDataURL(file)
      }
    },
    removeDocument(index) {
      this.loadedDocuments.splice(index, 1)
      // this.loadedDocumentNames.splice(index, 1)
    },
    addItem() {
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
      setTimeout(() => {
        this.editorReady = true
      }, 200)
    },
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      await showProgramme(this.editedItem)
        .then((response) => {
          this.editedItem = response.data.data
          this.dataImage = response.data.data.image
          if (response.data.data.media_type === 'photo') {
            this.dataImage2 = response.data.data.media_url
            this.editedItem.media_url = null
          }
          this.editedItem.image = null
          this.selectedLabels = response.data.data.labels
          let selectedExperts = response.data.data.experts
          selectedExperts.forEach(expert => {
            expert['id'] = expert.pivot.expert_id
          })
          this.selectedExperts = selectedExperts
          
          let selectedTherapeuticAreas = response.data.data.therapeutic_area
          selectedTherapeuticAreas.forEach(ta => {
            ta['id'] = ta.pivot.therapeutic_area_id
          })
          this.selectedTherapeuticAreas = selectedTherapeuticAreas

          response.data.data.documents.forEach(doc => {
            this.loadedDocuments.push({document_name: doc.document_name, btn_name: doc.btn_name, base64: ''})
          })
          //here
          this.selectedWebsites = response.data.data.websites
          this.editedItem.documents = null
          setTimeout(() => {
            this.editorReady = true
          }, 200)
        })
        .catch((error) => {
          console.log("error", error)
        })
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await deleteProgramme(this.editedItem)
        .then(() => {
          this.getItems()
          this.closeDelete()
        })
        .then((error) => {
          console.log("deleteProgramme error => ", error)
        })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dataImage = null
        this.dataImage2 = null
        this.editedIndex = -1
        this.imageUpdated = false
        this.selectedLabels = []
        this.selectedExperts = []
        this.loadedDocuments = []
        this.selectedTherapeuticAreas = []
        this.selectedDocs = []
        this.selectedWebsites = []
        this.spinnerActive = false
      })
    },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      this.spinnerActive = true
      let formValidated = this.$refs.form.validate()
      if (!formValidated) return
      let data = Object.assign({}, this.editedItem)
      if (this.imageUpdated) {
        data["image"] = this.dataImage
        data["file_name"] = this.fileName
      }

      if (data.media_type === 'photo') {
        data["media_url"] = this.dataImage2
        data["media_file_name"] = this.fileName2
      }
      
      let _selectedLabels = []
      this.selectedLabels.forEach(el => {
        _selectedLabels.push(el.id)
      })
      data["program_labels"] = _selectedLabels

      let _selectedTherapeuticAreas = []
      this.selectedTherapeuticAreas.forEach(el => {
        _selectedTherapeuticAreas.push(el.id)
      })
      data["program_therapeutic_areas"] = _selectedTherapeuticAreas
      
      let _selectedExperts = []
      this.selectedExperts.forEach(el => {
        _selectedExperts.push(el.id)
      })
      data["program_experts"] = _selectedExperts
      
      data["program_doc"] = this.loadedDocuments

      let _selectedWebsites = []
      this.selectedWebsites.forEach(el => {
        _selectedWebsites.push(el.id)
      })
      // data["program_websites"] = _selectedWebsites

      if (data.accreditation_id === null) {
        data.accreditation_id = ""
      }
      if (this.editedIndex > -1) {
        // EDIT
        data["program_websites"] = "regular_admin"
        await editProgramme(data)
          .then(() => {
            this.getItems()
            this.close()
          })
          .catch((error) => {
            console.log("editProgramme error => ", error)
            this.spinnerActive = false
          })
      } else {
        // ADD
        let _selectedWebsites = []
        this.selectedWebsites.forEach(el => {
          _selectedWebsites.push(el.id)
        })
        data["program_websites"] = _selectedWebsites
        await addProgramme(data)
          .then(() => {
            this.getItems()
            this.close()
          })
          .catch((error) => {
            console.log("addProgramme error => ", error)
            this.spinnerActive = false
          })
      }
    },
    removeLabelFromSelected(label) {
      this.selectedLabels = this.selectedLabels.filter(el => el.id !== label.id)
    },
    removeExpertFromSelected(expert) {
      this.selectedExperts = this.selectedExperts.filter(el => el.id !== expert.id)
    },
    removeTherapeuticAreaFromSelected(ta) {
      this.selectedTherapeuticAreas = this.selectedTherapeuticAreas.filter(el => el.id !== ta.id)
    },
    removeDocFromSelected(doc) {
      this.selectedDocs = this.selectedDocs.filter(el => el.id !== doc.id)
    },
    removeWebsiteFromSelected(doc) {
      this.selectedWebsites = this.selectedWebsites.filter(el => el.id !== doc.id)
    }
  },
}
</script>
